import { APP_DETAILS, BANK_DETAILS, BANNERS, SHOW_LEFT_SIDEBAR } from "./types";
import { axiosService } from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";
import { Toaster } from "../../utils/toaster";
import { AxiosError } from "axios";
import { APP_CONST } from "../../config/const";
const API_HOST_V2 = process.env.REACT_APP_API_HOST_V2;
export const getAppDetails = () => (dispatch) => {
  const result = axiosService.get(API_URLS.APP.APP_DETAILS);
  result.then((res) => {
    if (res?.data?.error === false) {
      dispatch({
        type: APP_DETAILS,
        payload: res?.data?.data,
      });
    }
  });
};
export const getActiveBankDetails = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.BANK_DETAILS);
    result
      .then((res) => {
        dispatch({
          type: BANK_DETAILS,
          payload: res.data.data.bankDetails,
        });
      })
      .catch((err) => {
        console.debug(err);
      });
  } catch (err) {
    console.debug(err);
  }
};

export const getBanners = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.GET_BANNERS);
    result.then((res) => {
      dispatch({
        type: BANNERS,
        payload: res.data?.data?.banners,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};
export const handleLeftSideBar = (booleanValue) => (dispatch) => {
  dispatch({
    type: SHOW_LEFT_SIDEBAR,
    payload: booleanValue,
  });
};

// Get notifications

export const getNotifications = async () => {
  try {
    return await axiosService.get(
      `${API_HOST_V2}${API_URLS.APP.GET_NOTIFICATIONS}`
    );
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err?.response?.data?.message || "Failed to get notifications"
      );
    }
    console.log(err);
  }
};

// check if demom user exist

export const checkIfDemoUserExist = async () => {
  try {
    return await axiosService.get(`${API_HOST_V2}${API_URLS.APP.GET_APP_DEMO}`);
  } catch (err) {
    if (err instanceof AxiosError) {
      console.log("err");
    }
  }
};
